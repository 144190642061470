// extracted by mini-css-extract-plugin
export var Category = "style-module--Category--ea036";
export var ContentWrapper = "style-module--ContentWrapper --05e03";
export var Details = "style-module--Details--ced28";
export var Heading = "style-module--Heading--0bbbd";
export var Links = "style-module--Links--e67f7";
export var Profile = "style-module--Profile--94d8a";
export var Project = "style-module--Project--ea7d2";
export var ProjectImage = "style-module--ProjectImage--52bb3";
export var ProjectImageWrapper = "style-module--ProjectImageWrapper--c7c54";
export var Section = "style-module--Section--79c68";
export var Tags = "style-module--Tags--78461";
export var Title = "style-module--Title--128de";